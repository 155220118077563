<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>My Stake </strong><small>Detail</small>
          </CCardHeader>
          <CCardBody>
            <CRow>
                        
              <CCol sm="4">
                <div class="">
                  <label> Payment Amount</label>
                </div>
              </CCol>
              <CCol sm="8">
                <div class="">
                  <label> : {{ detail.amountf }}</label>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="4">
                <div class="">
                  <label> Time Created</label>
                </div>
              </CCol>
              <CCol sm="8">
                <div class="">
                  <label> : {{ detail.time_created }}</label>
                </div>
              </CCol>
            </CRow>
            <CRow>    
              <CCol sm="4">
                <div class="">
                  <label> Payment Received</label>
                </div>
              </CCol>
              <CCol sm="8">
                <div class="">
                  <label> : {{ detail.receivedf }}</label>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="4">
                <div class="">
                  <label> Status Payment</label>
                </div>
              </CCol>
              <CCol sm="8">
                <div class="">
                  <label> : {{ sstatus[detail.status] }}</label>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="4">
                <div class="">
                  <label> Payment Address</label>
                </div>
              </CCol>
              <CCol sm="8">
                <div class="">
                  <label> : {{ detail.payment_address }}</label>
                </div>
              </CCol>
              </CRow>
            <hr />
            
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal title="Error Input" color="danger" :show.sync="myError" size="sm">
      <b>Error</b>! Data Progress pekerjaan gagal diinput.
    </CModal>

    <CModal title="Sukses Input" color="sucess" :show.sync="mySukses" size="sm">
      <b>Sukses</b>! Data Progress pekerjaan berhasil diinput.
    </CModal>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import * as Modals from "../notifications/Modals.vue";
import $ from "jquery";
// import { CurrencyInput } from 'vue-currency-input'
let tgl = new Date().getDate();
if(tgl<10){
  tgl = "0"+tgl;
}

let bln = new Date().getMonth();
bln = +bln + 1;
if(bln<10){
  bln = "0"+bln;
}

function hapusProgress(id) {
   this.Forms.hapusData(id);    
}

export default {
  // components: {CurrencyInput },
  name: "Forms",
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2019 },
        (value, index) => new Date().getFullYear() - index
      );
    },
  },
  data() {
    return {
      tgl_input : new Date().getFullYear()+"-"+bln +"-"+tgl,
      mySukses: false,
      myError: false,
      txid : this.$route.params.txid,
      sstatus :  [
        "Waiting Payment",
        "Paid",
        "Cancel",
      ],
      detail: "",
      laporan: "Pilih File Laporan Progress",
      foto: "Pilih File Foto Progress",
      selected: [], // Must be an array reference!
      laporan_progress : "",
      foto_progress : "",
      show: true,
      horizontal: { label: "col-3", input: "col-9" },
      selectedOption: "some value",

      formCollapsed: true,
      checkboxNames: [
        "Checkboxes",
        "Inline Checkboxes",
        "Checkboxes - custom",
        "Inline Checkboxes - custom",
      ],
      radioNames: [
        "Radios",
        "Inline Radios",
        "Radios - custom",
        "Inline Radios - custom",
      ],
    };
  },
  mounted() {
    this.showData();
    setTimeout(function() {
             this.showData();
        }, 2000);
    // this.loadData();
  },
  methods: {
    onFileChange(event) {
      var fileData = event.target.files[0];
      this.laporan = fileData.name;
      this.laporan_progress = fileData;
      // alert(fileData.name);
    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.foto = fileData.name;
      this.foto_progress = fileData;
      // alert(fileData.name);
    },
    // validator (val) {
    //   return val ? val.length >= 8 : false
    // },
    inputProgress: function (event) {
      // Simple POST request with a JSON body using axios
      // const progress = {
      //   id_rka: this.$route.params.id,
      //   laporan_progress: this.this.laporan_progress,
      //   foto_progress: this.foto_progress,
      //   realisasi_progress_fisik: this.realisasi_progress_fisik,
      //   rencana_progress_fisik: this.rencana_progress_fisik,
      //   dtime: this.tgl_input,
      // };
      const formData = new FormData()
      formData.append('laporan_progress', this.laporan_progress, this.laporan_progress.name)
      formData.append('foto_progress', this.foto_progress, this.foto_progress.name)
      formData.append('id_rka', this.$route.params.id)
      formData.append('foto', this.foto_progress.name)
      formData.append('laporan', this.laporan_progress.name)
      formData.append('realisasi_progress_fisik', this.realisasi_progress_fisik)
      formData.append('rencana_progress_fisik', this.rencana_progress_fisik)
      // alert(JSON.stringify(progress));
      axios
        .post(
          "https://sippd.probowsolution.com/api/upload_progress.php?key=AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
          formData
        )
        .then((response) => {
          // console.log(response);
          // alert(response.data + JSON.stringify(formData));
          if (response.data == "sukses") {
            this.mySukses = true;
            this.loadData();
          } else {
            this.myError = true;
          }
        });
    },
    hapusData: function (id) {
      alert(id);
    },
    showData: function (event) {
      // alert(this.$route.params.txid);
      // Simple POST request with a JSON body using axios
      
      // alert(JSON.stringify(pekerjaan));
      axios
        .post(
          process.env.VUE_APP_BASE_URL+"api/public/index.php/infopayment/" +
            this.$route.params.txid +
            "?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",false
        )
        .then((response) => {
          // alert(response);
          this.detail = response.data.result;
        });
    },    
    loadData: function () {
      let id_rka =  this.$route.params.id;
      console.log("https://sippd.probowsolution.com/api/ajax_detail_pekerjaan.php?action=table_data&k=" +
              id_rka);
      // alert(username);
        // your code here.
        // axios
        // .get("https://probowsolution.com/sippd/api/public/index.php/pekerjaan/2021?key=704b037a97fa9b25522b7c014c300f8a")
        // .then((response)=>
        // {
        // this.products = response.data;
        $("#datatable").DataTable({
          processing: true,
          serverSide: true,
          pageLength: 50,
          ajax: {
            url:
              "https://sippd.probowsolution.com/api/ajax_detail_pekerjaan.php?action=table_data&id_rka=" +
              id_rka,
            dataType: "json",
            type: "POST",
          },
          columns: [
            { data: "no" },
            { data: "foto_progress" },
            { data: "laporan_progress" },
            { data: "rencana_progress_fisik" },
            { data: "realisasi_progress_fisik" },
            { data: "tgl_input" },
            // { data: "edit" },
            { data: "unduh" },
            { data: "hapus" },
          ],
        });

        // })
      
    },
  },
};
</script>
